import { template as template_4c48225d9b964fb59a587d02fda24003 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4c48225d9b964fb59a587d02fda24003(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
