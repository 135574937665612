import { template as template_279e5c0fa25c44ba912045edcd5cb5ac } from "@ember/template-compiler";
const EmptyState = template_279e5c0fa25c44ba912045edcd5cb5ac(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
